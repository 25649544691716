<template>
  <div class="complaints">
    <Header :currentIndex="'7'"></Header>
    <div class="breadcrumb">
      <Breadcrumb :navTitle="'投诉维权'"></Breadcrumb>
    </div>
    <div class="breadcrumb-content">
      <div v-html="content"></div>
      <div class="breadcrumb-form">
        <span>留言给我们</span>
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
          >
            <div style="display: flex">
              <el-form-item prop="name">
                <el-input placeholder="请输入您的姓名" v-model="ruleForm.name">
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-user-solid"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="phone" style="margin-left: 140px">
                <el-input placeholder="请输入手机号" v-model="ruleForm.phone">
                  <i slot="prefix" class="el-input__icon el-icon-phone"></i>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-input type="textarea" v-model="ruleForm.content"></el-input>
            </el-form-item>

            <el-form-item>
              <el-upload
                class="upload-demo"
                :action="BaseUrl + 'api/weiquan/upload'"
                :before-remove="beforeRemove"
                :on-success="unploadSussess"
                multiple
                :limit="1"
                :file-list="fileList"
                style="display: inline-block"
              >
                <el-button type="info" style="margin-right: 167px; width: 300px"
                  >文件上传</el-button
                >
              </el-upload>

              <el-button type="primary" style="width: 300px" @click="Submit"
                >确认提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
export default {
  name: "Complaints",
  data() {
    return {
      content: "",
      ruleForm: {
        name: "",
        phone: "",
        content: "",
      },
      fileUrl: "",
      fileList: [],
      filetype: "",
      rules: {
        name: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          { min: 2, max: 5, message: "名字长度在2-5个字符", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "您输入的手机号码位数不对",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http({
        url: "/weiquan/index",
      }).then((res) => {
        this.content = res.data;
      });
    },
    unploadSussess(response) {
      console.log(response);
      if (response.code == 1) {
        this.fileUrl = response.data.fullurl;
        this.$message({
          message: response.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: response.msg,
          type: "warning",
        });
        this.fileUrl = "";
        this.fileList = [];
      }

      console.log(this.fileUrl);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    Submit() {
      if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(this.fullurl)) {
        this.filetype = 1;
      } else {
        this.filetype = 0;
      }
      console.log(this.fullurl, "url");
      if (this.ruleForm.name && this.ruleForm.phone) {
        let Tphone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!Tphone.test(this.ruleForm.phone)) {
          console.log("手机号不正确");
          this.$message({
            message: "输入正确的手机号",
            type: "warning",
          });
        } else {
          console.log(this.ruleForm);
          this.$http({
            url: "/weiquan/common",
            method: "POST",
            data: {
              ...this.ruleForm,
              filename: this.fileUrl,
              filetype: this.filetype,
            },
          }).then((res) => {
            console.log(res, "Submit");

            if (res.code == 1) {
              this.ruleForm = {
                name: "",
                phone: "",
                content: "",
              };
              (this.fileUrl = ""), (this.fileList = []);
              this.$message({
                message: res.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        }
      } else {
        this.$message({
          message: "请填写您的信息",
          type: "warning",
        });
      }
    },
  },
  components: {
    Header,
    Breadcrumb,
    Footer,
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 300px !important;
  margin-left: -100px;
  height: 36px !important;
}
/deep/.el-textarea__inner {
  width: 1180px !important;
  height: 184px !important;
  margin-bottom: 85px !important;
}
/deep/.el-textarea {
  width: 1180px !important;
  height: 184px !important;
  margin-left: -100px !important;
  margin-bottom: 85px !important;
}
/deep/.el-upload-list__item-name {
  margin-bottom: -55px;
}
.complaints {
  .breadcrumb {
    margin-top: -30px;
  }
  .breadcrumb-content {
    width: 1200px;
    margin: 0 auto;

    > span:nth-child(1) {
      display: block;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 50px;
      text-align: center;
      color: #333333;
    }
    > span:nth-child(2) {
      display: block;
      font-size: 16px;
      margin-bottom: 36px;
      color: #333333;
    }
    .breadcrumb-link {
      font-size: 16px;
      margin-bottom: 70px;
      color: #333333;
      > div:nth-child(1) {
        margin-bottom: 15px;
      }
      > div:nth-child(2) {
        display: flex;
        > div:nth-child(2) {
          width: 500px;
          > p {
            margin-bottom: 15px;
          }
        }
      }
      > div:nth-child(3) {
        display: flex;
        > div:nth-child(2) {
          width: 500px;
          > p {
            margin-bottom: 15px;
          }
        }
      }
    }
    .breadcrumb-form {
      margin: 160px 0;
      > span:nth-child(1) {
        display: block;
        font-size: 25px;
        margin-bottom: 49px;
        color: #333333;
      }
    }
  }
}
</style>
